require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
// const imagePath = (name) => images(name, true)

// import jquery
var $ = require("jquery");

import "bootstrap/dist/js/bootstrap.bundle.min";
import "../vendor/cocoon";

// import stylesheets
import "../stylesheets/application";
import "prismjs/themes/prism";

import "controllers";

// Import TinyMCE
import * as tinymce from 'tinymce/tinymce';
import "prismjs/prism";
import * as ClipboardJS from "clipboard";

// copy tinymce's skins directory into public/packs
require.context(
  '!file-loader?name=[path][name].[ext]&context=node_modules/tinymce&outputPath=js!tinymce/skins',
  true,
  /.*/
);

// copy tinymce's themes directory into public/packs
require.context(
  '!file-loader?name=[path][name].[ext]&context=node_modules/tinymce&outputPath=js!tinymce/themes',
  true,
  /.*/
);

// Import TinyMCE theme
import 'tinymce/themes/silver/theme';

// Import TinyMCE plugins
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/media';

// Initialize tinymce w/ theme and plugins
tinymce.init({
  selector: '.tinymce',
  toolbar: 'styleselect codesample | bold italic | superscript subscript | undo redo | image media | link | code',
  plugins: [
    'code',
    'codesample',
    'image',
    'link',
    'media'
  ],
  codesample_languages: [
    {text: 'JavaScript', value: 'javascript'},
    {text: 'HTML/XML', value: 'markup'},
  ],
});

$(document).ready(() => {
  var clipboard = new ClipboardJS(".clipboard");
  var copyButton = $("template#copy-button").html();
  var $copyButton = $(copyButton);
  $copyButton.popover();

  // adds a copy code button to each code snippet
  $("pre.language-javascript").each(function(index) {
    $(this).css("position", "relative");
    var code = $(this).text();
    $copyButton.attr("data-clipboard-text", code);
    $(this).prepend($copyButton.clone(true));
  });
});
